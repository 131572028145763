<template>
  <TopBar />
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
      <div class="col-lg-12">
        <ol>
          <li class="text-danger"><a href="/">Início</a></li>
          <li><a href="/sou-consumidor" class="text-dark">Sou consumidor</a></li>
        </ol>
        <h2>Cadastro do Consumidor</h2>
      </div>
    </div>
  </section>

  <section class="inner-page mt-5">
    <div class="container">
      <div class="col-lg-12">

      <p style="font-size: 13px" data-aos="fade-right">
        Neste espaço você consumidor pode solicitar avaliação de seu limite de
        crédito, para isso basta informar o Número do documento e senha token
        recebidas por email e SMS e seguir os demais passos.
      </p>
      <div style="margin-top: 50px"></div>

      <div class="row">
        <div class="col-lg-5 text-center" id="wrapperBoletoGarantido">
          <div class="align-middle">
            <div class="col-md-10 mx-auto">
              <label for="cnpj">
                <strong>Documento (CPF/CNPJ)</strong>
                <span class="required text-danger">*</span>
              </label>
              <input type="text" size="30" value aria-required="true" onchange="AplicarMascaraCpfCnpj(this)" class="form-control" autocomplete="off" required/>
              <div style="margin-top: 50px"></div>
            </div>

            <div class="row">
              <div class="col-md-10 mx-auto">
                <label for="cnpj">
                  <strong>Token</strong>
                  <span class="required text-danger">*</span>
                </label>
                <input type="text" size="30" value aria-required="true" onchange="AplicarMascaraCpfCnpj(this)" class="form-control" autocomplete="off" required/><br />
                <a href="#" class="btn btn-danger" style="background:#e51937; border-radius: 0px;">Iniciar cadastro</a>
                <div style="margin-top: 50px"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-7">
          <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="assets/images/banner_01.jpg" class="d-block w-100" alt="First slide"/>
              </div>
              <div class="carousel-item">
                <img src="assets/images/banner_02.jpg" class="d-block w-100" alt="Second slide"/>
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Anterior</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Proximo</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
  </section>

<div style="margin-top: 100px"></div>

  <Footer />
</template>

<script>
import Footer from "../components/Footer.vue";
import TopBar from "../components/TopBar.vue";

export default {
  name: "CadastroConsumidor",
  components: {
    TopBar,
    Footer,
  },
};
</script>

<style scoped>
.breadcrumbs {
  padding: 45px 0;
  margin-top: 4rem;
  background: #efefef;
}
@media only screen and (max-width: 320px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}
</style>
