<template>
  <footer id="footer">
    <div class="footer-top">
      <div class="container">
          <div class="col-lg-12">
            <div class="row">
            <div class="col-lg-4 col-md-6">

                <div class="footer-info">
                <h3><img href="/" width="150px" style="margin-top: 1px" src="../../public/assets/images/logo_creditall_inverso.png"/></h3>
                <a href="https://goo.gl/maps/RvKqPfXkoQ31Lfdg9" target="blanck">
                    <p>
                    Rua 14 de Dezembro, 45<br />
                    Centro | Mandaguaçu - PR
                    </p></a>

                <div style="margin-top: 50px"></div>

                <p>Telefone: (41) 3205-1333</p>
                <p>E-mail: <a href="mailto:contato@creditall.com.br">contato@creditall.com.br<br/></a></p>

                <div class="social-links mt-3">
                    <a href="https://pt-br.facebook.com/creditall.inteligenciadecredito/" target="blanck" class="twitter"><i class="bi bi-facebook"></i></a>
                    <a href="http://wa.me/554140421642" target="blanck" class="twitter"><i class="bi bi-whatsapp"></i></a>
                </div>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 footer-links">
                <h4>Acesso rápido</h4>
                <ul>
                <li><i class="fas fa-angle-right" style="color:#e51937"></i><a href="/#">⠀Inicio</a></li>
                <li><i class="fas fa-angle-right" style="color:#e51937"></i><a href="/#empresa">⠀Sobre</a></li>
                <li><i class="fas fa-angle-right" style="color:#e51937"></i><a href="/#servicos">⠀Serviços</a></li>
                <li><i class="fas fa-angle-right" style="color:#e51937"></i><a href="/#lgpd">⠀LGPD</a></li>
                <li><i class="fas fa-angle-right" style="color:#e51937"></i><a href="/#contato">⠀Contato</a></li>
                <li><i class="fas fa-angle-right" style="color:#e51937"></i><a href="/trabalhe-conosco">⠀Trabalhe Conosco</a></li>
                <li><i class="fas fa-angle-right" style="color:#e51937"></i><a href="https://siscredit.com.br/sisrepr/" target="_blanck">⠀Licenciado</a></li>
                </ul>
            </div>

            <div class="col-lg-2 col-md-6 footer-links">
                <h4>Produtos e serviços<span class="position-absolute top-0 translate-middle badge rounded-pill bg-danger" style="background: #e51937; font-size: 10px">Novo<span class="visually-hidden">unread messages</span></span></h4>
                <ul>
                <li><i class="fas fa-angle-right" style="color:#e51937"></i><a href="/#servicos">⠀Garantia de Crédito</a></li>
                <li><i class="fas fa-angle-right" style="color:#e51937"></i><a href="/#garantiaaluguel">⠀Garantia de Aluguel</a></li>
                <li><i class="fas fa-angle-right" style="color:#e51937"></i><a href="/#plataformacredito">⠀Plataforma de Crédito</a></li>
                </ul>
            </div>

            <div class="col-lg-2 col-md-6 footer-links">
                <h4>Área do Cliente</h4>
                <ul>
                <li><i class="fas fa-angle-right" style="color:#e51937"></i><a href="/sou-lojista">⠀Sou Lojista</a></li>
                <li><i class="fas fa-angle-right" style="color:#e51937"></i><a href="/sou-consumidor">⠀Sou Consumidor</a></li>
                <li><i class="fas fa-angle-right" style="color:#e51937"></i><a href="https://www.siscredit.com.br/boletos/extrato.php">⠀Sou Locatário</a></li>
                </ul>
            </div>

            <div class="col-lg-2 col-md-6 footer-links">
                <h4>Área de Desenvolvimento</h4>
                <ul>
                <li><i class="fas fa-angle-right" style="color:#e51937"></i><a href="/desenvolvedores">⠀Área destinada a<br>⠀desenvolvedores</a></li>
                </ul>
            </div>

            </div>
        </div>
      </div>
    </div>


    <div class="container">
      <div class="copyright" style="font-size: 12px">
          © 2008 - {{ new Date().getFullYear() }} | <span><a href="/">Creditall Holding Empresarial</a></span> - Todos os direitos reservados
        <strong></strong>
      </div>
    </div>
  </footer>

<!-- ======= Cookies  -->
    <div class="box-cookies hide" data-aos="fade-up">
        <p class="msg-cookies">Queremos garantir a melhor navegação e personalizar sua experiência no nosso site. Por isso, coletamos informações pessoais de preferências e interesses por meio de cookies e outras tecnologias semelhantes. Entenda como usamos os dados armazenados na nossa
        <a href="/politicas-privacidades" class="text-decoration-underline">Política de Cookies</a>.</p>
        <button v-on:click="acceptCookies" class="btn-cookies">Prosseguir</button>
    </div>
 <!--End Cookies -->

</template>


<script>
/*(function (w, d, s, u) {
  w.RocketChat = function (c) {
    w.RocketChat._.push(c);
  };
  w.RocketChat._ = [];
  w.RocketChat.url = u;
  var h = d.getElementsByTagName(s)[0],
    j = d.createElement(s);
  j.async = true;
  j.src =
    "https://chat.creditall.com.br/livechat/rocketchat-livechat.min.js?_=201903270000";
  h.parentNode.insertBefore(j, h);
})(window, document, "script", "https://chat.creditall.com.br/livechat");*/


//if (localStorage.creditallCookie) {
//    document.querySelector('.box-cookies').classList.add('hide');
//}

//const acceptCookies = () => {

  //  document.querySelector('.box-cookies').classList.add('hide')
    //localStorage.setItem("creditallCookie", "accept");
//}

//const btnCookies = document.querySelector(".btn-cookies");

//btnCookies.addEventListener('click', acceptCookies);


export default {
  name: "Footer",
  mounted(){
      if (!localStorage.creditallCookie) {
        document.querySelector('.box-cookies').classList.remove('hide');
      }

      const acceptCookies = () => {

        document.querySelector('.box-cookies').classList.add('hide')
        localStorage.setItem("creditallCookie", "accept");
    }

    const btnCookies = document.querySelector(".btn-cookies");

    btnCookies.addEventListener('click', acceptCookies);

    //  acceptCookies(){
    //    document.querySelector('.box-cookies').classList.add('hide')
    //    localStorage.setItem("creditallCookie", "accept");
    //  }
  },
};
</script>


<style scoped>
#footer {
  background: #343639;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}
#footer .footer-top {
  padding: 60px 0 30px 0;
}
#footer .footer-top .footer-info {
  margin-bottom: 15px;
  background: #303033;
  color: #fff;
  border-top: 4px solid #e51937;
  text-align: center;
  padding: 30px 20px;
}
#footer .footer-top .footer-info h3 {
  font-size: 36px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}
#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
}
#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #262626;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
#footer .footer-top .social-links a:hover {
  background: #e51937;
  color: #fff;
  text-decoration: none;
}
#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}
#footer .footer-top .footer-links {
  margin-bottom: 30px;
}
#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #e51937;
  font-size: 18px;
  line-height: 1;
}
#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}
#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}
#footer .footer-top .footer-links ul a:hover {
  color: #e51937;
}
#footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4;
}
#footer .footer-top .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}
#footer .footer-top .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #e51937;
  color: #fff;
  transition: 0.3s;
  border-radius: 4;
}
#footer .footer-top .footer-newsletter form input[type="submit"]:hover {
  background: #e51937;
}
#footer .copyright {
  border-top: 1px solid #262626;
  text-align: center;
  padding-top: 30px;
}
#footer .credits {
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}
#footer .credits a {
  color: #e51937;
}

/*--------------------------------------------------------------
# Cookies
--------------------------------------------------------------*/
.hide {
  display: none !important;
}

.box-cookies {
  position: fixed;
  flex-direction: column;
  background: rgba(0, 0, 0, .8);
  box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
  margin-left: 20px;
  margin-right: 15px;
  border-radius: 5px;
  padding: 1rem;
  z-index: 99999;
  bottom: 1rem;
  align-items: center;
  justify-content: space-between;
}

.box-cookies .msg-cookies,
.box-cookies .btn-cookies {
  padding: 5px;
  color: #fff;
  font-size: 13px;
}

.box-cookies .btn-cookies {
  background: #e51937;
  cursor: pointer;
  align-self: normal;
  position: relative;
  display: block;
  color: white;
  font-size: 12px;
  text-decoration: none;
  margin: 5px 0;
  border: 2px solid #e51937;
  border-radius: 0px;
  padding: 10px 30px;
  text-transform: uppercase;
  overflow: hidden;
  transition: 1s all ease;
}

</style>
