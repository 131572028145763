<template>
  <HeaderGA />

<!----- INICIO ----->
<section id="inicio" class="inicio">
  <div style="margin-top: 50px"></div>
  <div class="container">
      <div class="col-lg-12">
        <div class="row justify-content-center">

          <div class="col-lg-6 pt-4 pt-lg-0 justify-content-center ml-2" data-aos="fade-right">
            <div class="section-title-inicio" data-aos="fade-up">
              <div class="section-title-inicio">
                <span>PAGUE E ALUGUE PELO CreditallPay®</span>
                <h2>PAGUE E ALUGUE PELO<br> CreditallPay®</h2>
              </div>
            </div>
            <p>Com o CreditallPay® Aluguel Garantido, você tem 100% de certeza nos recebimentos de seus aluguéis!</p>
            <p>O CreditallPay®, é inteligente, prático, rápido, sem burocracia, sem fiador, sem depósito caução ou comprovação de renda.</p>
            <a href="/sobre-garantia-aluguel" class="btn btn-danger" style="margin-top: 1rem; border-radius: 0px;">Conheça nosso novo produto</a>
            <div style="margin-top: 25px"></div>
          </div>

          <div class="col-lg-5 d-flex" data-aos="fade-left">
            <img src="../../public/assets/images/istockphoto-639678734-170667a.jpg" class="img-fluid rounded shadow" style="" alt=""/>
          </div>

        </div>
      </div>
    </div>
</section>
<!----- FIM INICIO ----->


<div style="margin-top: 100px"></div>


<!----- COMO FUNCIONA ----->
<section id="comofunciona" class="comofunciona">
  <div class="page-center">
    <div class="col-lg-12">
    <h3>Como funciona o CreditallPay® Garantia de Aluguel</h3>
    <div class="row cm-how-steps" style="justify-content">

          <div class="step col-lg-3">
            <p style="font-size: 20px">1</p>
            <span class="circle text-body"><i class="fas fa-id-card"></i></span>
            <p class="fw-bold">Você apresenta seu documento (CPF ou CNPJ), o valor do aluguel, inclui seus dados de endereço e após...</p>
          </div>

          <div class="step col-lg-3">
            <p style="font-size: 20px">2</p>
            <span class="circle text-body"><i class="fas fa-search"></i></span>
            <p class="fw-bold">... realizamos a análise* de crédito em até 10 segundos e, aprovado seu cadastro...</p>
          </div>

          <div class="step col-lg-3">
            <p style="font-size: 20px">3</p>
            <span class="circle text-body"><i class="fas fa-file-signature"></i></span>
            <p class="fw-bold">... é realizada a assinatura do contrato.</p>
          </div>

          <div class="step last col-lg-3">
            <p style="font-size: 20px">4</p>
            <span class="circle text-body"><i class="far fa-smile-beam"></i></span>
            <p class="fw-bold">Parabéns! Agende a entrega das chaves!</p>
          </div>

    </div>
    <h4>Seu novo imóvel foi alugado com Sucesso!</h4>
    <p>*Em caso de decisão de crédito não aprovada, pediremos outras informações para uma nova análise de crédito.</p>
  </div>
  </div>
</section>
<!----- FIM COMO FUNCIONA ----->


<div style="margin-top: 50px"></div>


<!----- BENEFICIOS ----->
<section id="beneficios" class="beneficios">
<div style="margin-top: 75px"></div>
<div class="container">
  <div class="section-title" data-aos="fade-up">
    <div class="section-title-beneficios">
      <span>VEJA OS BENEFÍCIOS QUE O CreditallPay® OFERECE:</span>
      <h2>VEJA OS BENEFÍCIOS QUE O CreditallPay® OFERECE:</h2>
    </div>
  </div>

      <div class="col-lg-12 d-flex justify-content-center">
        <div class="col-lg-10">
          <div class="row justify-content-center">

            <div class="col-lg-6 col-md-6 d-flex align-items-stretch mt-4">
              <div class="info-box mb-4">
                <h3>PARA TODOS OS IMÓVEIS</h3>
                <p>Dos pequenos aos grandes imóveis, dos mais acessíveis aos de alto valor, dos residenciais aos comerciais, com o CreditallPay® garantimos o seu aluguel.</p>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 d-flex align-items-stretch mt-4">
              <div class="info-box mb-4">
                <h3>MAIS PRATICIDADE PARA ALUGAR UM IMÓVEL</h3>
                <p>Você não precisa se preocupar em ir atrás de um fiador ou dar um valor adiantado como caução. O CreditallPay® é sua garantia do aluguel.</p>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 d-flex align-items-stretch mt-4">
              <div class="info-box mb-4">
                <h3>ALTA PERFORMANCE IMOBILIÁRIA</h3>
                <p>Disponibilizamos atendimento online, com consultores especializados a sua disposição, te auxiliando e tirando suas dúvidas.</p>
              </div>
            </div>


            <div class="col-lg-6 col-md-6 d-flex align-items-stretch mt-4">
              <div class="info-box mb-4">
                <h3>ANÁLISE DE CRÉDITO ONLINE</h3>
                <p>Possuímos uma análise de crédito rápida e eficiente protegendo todos os seus dados e te dando a garantia de segurança.</p>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 d-flex align-items-stretch mt-4">
              <div class="info-box mb-4">
                <h3>AGILIDADE NO PROCESSO DE LOCAÇÃO</h3>
                <p>Com o aluguel garantido você realiza sua análise cadastral, no conforto de sua residencia.</p>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 d-flex align-items-stretch mt-4">
              <div class="info-box mb-4">
                <h3>RENOVAÇÃO DA GARANTIA SIMPLIFICADA</h3>
                <p>Caso as condições iniciais de aceitação não sofram alterações, não será necessário fazer uma nova análise do cadastro para a renovação da garantia.</p>
              </div>
            </div>

            </div>

        </div>
      </div>
</div>
</section>




<!----- FIM BENEFICIOS ----->


<div style="margin-top: 50px"></div>


<!----- ALUGUE ----->
<section id="alugue" class="alugue">
<div style="margin-top: 75px"></div>
<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-12">
      <div class="row justify-content-center">
        <div class="col-lg-6 justify-content-start content" data-aos="fade-right">
          <div class="section-title" data-aos="fade-up">
            <div class="section-title-alugue">
              <span>Alugue sem Burocracia</span>
              <h2>Alugue sem Burocracia</h2>
            </div>
            <p style="font-size: 16px">
              Com o CreditallPay®, você não depende de fiador para alugar sua casa.
              Com o nosso serviço e as nossas parcerias com as imobiliárias, conseguimos as menores e melhores taxas para seu inquilino.
            </p>
            <!--<a href="" class="btn btn-danger" style="margin-top: 2rem; background: #e51937; border-radius: 0px;">Encontre uma Imobiliária</a>-->
            <div style="margin-top: 25px"></div>
          </div>
        </div>
        <div class="col-lg-5 d-flex justify-content-center" data-aos="fade-left">
          <img src="assets/images/sem_fiador.jpg" class="img-fluid rounded shadow" style="shadow" width="500" height="300" alt=""/>
        </div>
      </div>
    </div>
  </div>
</div>
</section>
<!----- FIM ALUGUE ----->


<div style="margin-top: 50px"></div>


<!----- VANTAGENS ----->
<section id="vantagens" class="vantagens">
<div style="margin-top: 75px"></div>

  <div class="container">
    <div class="section-title-vantagens">
      <span>Vantagens</span>
      <h2>Vantagens</h2>
    </div>


    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
        <div class="icon-box">
          <div class="icon"><i class="fas fa-key"></i></div>
          <h4>IMOBILIÁRIAS E CORRETORES</h4>
          <div class="text">
            <p><i class="bi bi-check2 text-danger"></i> Ampliação do número de locações</p><br>
            <p><i class="bi bi-check2 text-danger"></i> Faturamento adicional com alugueis</p><br>
            <p><i class="bi bi-check2 text-danger"></i> Agilidade no fechamento dos contratos de aluguel</p><br>
            <p><i class="bi bi-check2 text-danger"></i> Sem burocracias, 100% online</p><br>
            <p><i class="bi bi-check2 text-danger"></i> Garantia de recebimento</p><br>
            <p><i class="bi bi-check2 text-danger"></i> Análise de crédito em até 10 segundos</p><br>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
        <div class="icon-box">
          <div class="icon"><i class="fas fa-user-tie"></i></div>
          <h4>PROPRIETÁRIOS DE IMOVEIS</h4>
          <div class="text">
            <p><i class="bi bi-check2 text-danger"></i> Menos tempo com o imóvel vazio</p><br>
            <p><i class="bi bi-check2 text-danger"></i> Garantia de recebimento das locações</p><br>
            <p><i class="bi bi-check2 text-danger"></i> Proteção contra danos na devolução do imóvel</p><br>
            <p><i class="bi bi-check2 text-danger"></i> Repasse rápido dos valores em caso de inadimplência</p><br>
            <p><i class="bi bi-check2 text-danger"></i> Análise de crédito eficiente para diminuir inadimplência</p><br>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
        <div class="icon-box">
          <div class="icon"><i class="fas fa-users"></i></div>
          <h4>PARA QUEM ALUGA</h4>
          <div class="text">
            <p><i class="bi bi-check2 text-danger"></i> Não precisa de fiador, caução ou comprovação de renda</p><br>
            <p><i class="bi bi-check2 text-danger"></i> Análise instantânea</p><br>
            <p><i class="bi bi-check2 text-danger"></i> Pagamento mensal atraves de boleto bancário, Pix ou cartão de crédito</p><br>
            <p><i class="bi bi-check2 text-danger"></i> Rapidez no processo de locação do seu imóvel</p><br>
            <p><i class="bi bi-check2 text-danger"></i> Liberdade para alugar 100% online</p><br>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 100px"></div>
  </div>
</section>
<!----- FIM VANTAGENS ----->



<!----- SEGURANCA ----->
<section id="seguranca" class="seguranca">
<div style="margin-top: 75px"></div>
<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-12">
      <div class="row justify-content-center">
        <div class="col-lg-5 d-flex justify-content-center" data-aos="fade-right">
          <img src="assets/images/seguranca1.jpg" class="img-fluid rounded shadow" style="shadow" width="500" height="300" alt=""/>
        </div>
        <div class="col-lg-6 justify-content-start content" data-aos="fade-left">
          <div style="margin-top: 25px"></div>
          <div class="section-title">
            <div class="section-title-seguranca">
              <span>UM NOVO JEITO DE ALUGAR</span>
              <h2>UM NOVO JEITO DE ALUGAR</h2>
            </div>
          </div>
            <p style="font-size: 18px">
              Com o CreditallPay® você tem a segurança!
            </p>
            <p style="font-size: 18px">
              A tecnologia empregada no sistema de análise de crédito da garantia locatícia da Creditall utiliza o cruzamento
               de informações de bureaus de crédito para garantir a segurança de seus dados.
            </p>
          </div>
        </div>
    </div>
  </div>
</div>
</section>
<!----- FIM SEGURANCA ----->


<div style="margin-top: 50px"></div>


<!----- PARCEIROS ----->
<section id="parceiros" class="parceiros">
<div style="margin-top: 75px"></div>
  <div class="section-title-parceiros">
    <span>Parceiros</span>
    <h2>Parceiros</h2>
  </div>

  <section id="logos" class="logos">
    <div class="container" data-aos="zoom-in">
      <div class="col-lg-12">
        <div class="row d-flex justify-content-center align-items-center">

          <div class="col-lg-2 col-md-4 col-6">
            <img src="../../public/assets/images/clients/pecunia2.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6">
            <img src="../../public/assets/images/clients/RGS.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6">
            <img src="../../public/assets/images/clients/3.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6">
            <img src="../../public/assets/images/clients/DS-Logo-Black1.png" class="img-fluid" alt="">
          </div>

        </div>
      </div>
    </div>
  </section>


</section>
<!----- FIM PARCEIROS ----->


<div style="margin-top: 50px"></div>


<!----- QUERO SER ----->
<section id="queroser" class="queroser">
<div style="margin-top: 75px"></div>
  <div class="section-title-quero">
    <span>Quero ser Cliente</span>
    <h2>Quero ser Cliente</h2>
  </div>
  <div class="mb-5" data-aos="fade-right">
    <h5 class="text-center">Seja parceiro e alugue muito mais com o CreditallPay®</h5>
    <div class="d-grid gap-2 col-4 mx-auto" style="margin-top: 25px">
      <a href="/cadastro-garantia-aluguel" class="btn btn-danger" style="border-radius: 0px;">Fale conosco</a>
    </div>
  </div>
</section>
<!----- FIM QUERO SER ----->

<a href="#" class="back-to-top d-flex align-items-center justify-content-center" style="margin-right: 60px; margin-top: 2rem">
    <i class="bi bi-arrow-up-short"></i>
  </a>

<div style="margin-top: 100px;"></div>


  <FooterGA />
</template>


<script>
import FooterGA from "../components/FooterGA.vue";
import HeaderGA from "../components/HeaderGA.vue";

export default {
  name: "GarantiaAluguel",
  components: {
    HeaderGA,
    FooterGA,
  },
};


</script>

<style scoped>
/*------------------*/
/*----- INICIO -----*/
.section-title-inicio {
  text-align: center;
  padding: 20px 0;
  position: relative;
}
.section-title-inicio h2 {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}
.section-title-inicio span {
  position: absolute;
  top: 0px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 42px;
  line-height: 1;
}
.section-title-inicio p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}
/*----- FIM INICIO -----*/
/*----------------------*/


/*-------------------------*/
/*----- COMO FUNCIONA -----*/
.comofunciona {
  justify-content: center;
  text-align: center;
  background: linear-gradient(rgba(2, 2, 2, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../public/assets/images/photo-1547866751-446296f133ce.png") fixed center center;
  background-size: cover;
  padding: 120px 0;
}
.comofunciona h3 span {
  color: #fff;
}

.comofunciona {
  justify-content: center;
  text-align: center;
  color: #fff;
  padding: 30px 0;
  background: linear-gradient(rgba(2, 2, 2, 0.7), rgba(0, 0, 0, 0.7)),
  url("../../public/assets/images/photo-1547866751-446296f133ce.png") fixed center center;
  background-size: cover;
  padding: 120px 0;
}

.page-center, .dnd-section > .row-fluid, .content-wrapper {
  max-width: 1088px;
}
.dnd-section > .row-fluid, .page-center {
  margin: 0 auto;
}

.comofunciona .cm-how-steps .step {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}


.comofunciona .cm-how-steps .step .circle {
  position: relative;
  font-family: "Gotham", sans-serif;
  font-size: 46px;
  font-weight: 700;
  line-height: 64px;
  width: 89px;
  height: 89px;
  display: flex;
  margin-top: 25px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .comofunciona .cm-how-steps .step .circle {
      font-size: 40px;
      width: 79px;
      height: 79px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .comofunciona .cm-how-steps .step .circle {
      font-size: 35px;
      width: 69px;
      height: 69px;
    }
}

@media(max-width:767px){
    .comofunciona .cm-how-steps .step .circle {
      font-size: 30px;
      width: 59px;
      height: 59px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .comofunciona .cm-how-steps .step .circle {
      font-size: 30px;
      width: 59px;
      height: 59px;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .comofunciona .cm-how-steps .step .circle {
      font-size: 25px;
      width: 49px;
      height: 49px;
    }
}


.comofunciona .cm-how-steps .step p {
  font-family: "Open Sans", sans-serif;
  line-height: 28px;
  letter-spacing: -0.5px;
  margin-top: 22px;
  width: 185px;
  margin-bottom: 0;
}


.comofunciona p {
  margin-bottom: 0;
}
p {
  margin: 0 0 1.4rem;
}
.comofunciona .cm-how-steps .step .circle:after {
  content: "";
  position: absolute;
  right: -90px;
  width: 44px;
  height: 30px;
}

.comofunciona .cm-how-steps {
  display: flex;
  margin: 88px 0;
}

.comofunciona h3 {
  color: #fff;
  letter-spacing: -0.8px;
  font-size: 26px;
  line-height: 36px;
  font-weight: 700;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .comofunciona h3 {
      font-size: 24px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .comofunciona h3 {
      font-size: 22px;
    }
}

@media(max-width:767px){
    .comofunciona h3 {
      font-size: 22px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .comofunciona h3 {
      font-size: 20px;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .comofunciona h3 {
      font-size: 18px;
    }
}


.comofunciona h4 {
  font-size: 26px;
  line-height: 36px;
  letter-spacing: -0.8px;
  margin-bottom: 8px;
  font-weight: 700;
  font-family: "Gotham Rounded", sans-serif;
  color: #fff;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .comofunciona h4 {
      font-size: 24px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .comofunciona h4 {
      font-size: 22px;
    }
}

@media(max-width:767px){
    .comofunciona h4 {
      font-size: 22px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .comofunciona h4 {
      font-size: 20px;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .comofunciona h4 {
      font-size: 18px;
    }
}
/*----- FIM COMO FUNCIONA -----*/
/*-----------------------------*/



/*----------------------*/
/*----- BENEFICIOS -----*/
.beneficios .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 40px 20px 30px 20px;
}
.beneficios .info-box i {
  font-size: 52px;
  color: #e51937;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #f5a0a0;
}
.beneficios .info-box h3 {
  font-size: 20px;
  color: #444444;
  font-weight: 700;
  margin: 10px 0;
}
.beneficios .info-box p {
  padding: 20px;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .beneficios .info-boxs span {
      font-size: 40px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .beneficios .info-box span {
      font-size: 38px;
      top: 30px;
      line-height: 0;
    }
}

@media(max-width:767px){
    .beneficios .info-box span {
      font-size: 38px;
      top: 30px;
      line-height: 0;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .beneficios .info-box span {
      font-size: 35px;
      top: 30px;
      line-height: 0;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .beneficios .info-box span {
      font-size: 20px;
      line-height: 0;
    }
}

section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title-beneficios {
  text-align: center;
  padding: 20px;
  position: relative;
}
.section-title-beneficios h2 {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
}
.section-title-beneficios span {
  position: absolute;
  top: 0px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 32px;
  line-height: 1;
}
.section-title-beneficios p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}




/*----- FIM BENEFICIOS -----*/
/*--------------------------*/



/*------------------*/
/*----- ALUGUE -----*/
section {
  padding: 30px 0;
  overflow: hidden;
}

.section-title-alugue {
  text-align: center;
  padding: 30px 0;
  position: relative;
}
.section-title-alugue h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}
.section-title-alugue span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 42px;
  text-transform: uppercase;
}
.section-title-alugue p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .section-title-alugue span {
      font-size: 40px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .section-title-alugue span {
      font-size: 38px;
      top: 30px;
    }
}

@media(max-width:767px){
    .section-title-alugue span {
      font-size: 38px;
      top: 30px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .section-title-alugue span {
      font-size: 35px;
      top: 30px;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .section-title-alugue span {
      font-size: 20px;
    }
}

/*----- FIM ALUGUE -----*/
/*----------------------*/



/*---------------------*/
/*----- VANTAGENS -----*/
.icon-box a#botao.btn.btn-danger.text-light {
  background-color:#e51937;
  position: absolute;
  bottom: 0;
  border: #fff;
}

.vantagens .icon-box {
  padding: 60px 30px;
  transition: all ease-in-out 0.3s;
  background: #fefefe;
  box-shadow: 0px 50px 90px 0px rgba(110, 123, 131, 0.1);
  border-radius: 18px;
  border-bottom: 5px solid #fff;
}


.vantagens .icon-box .icon {
  width: 64px;
  height: 64px;
  background: #e51937;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: ease-in-out 0.3s;
  color: #fff;
}


.vantagens .icon-box .icon-botao{
  width: 44px;
  height: 44px;
  background: #e51937;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
  color: #fff;
}

.vantagens .icon-box img {
  width: 10rem;
  height: 10rem;
  position: absolute;
  top: -5rem;
  left: 91%;
}

.vantagens .icon-box .icon i {
  font-size: 28px;
}
.vantagens .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}
.vantagens .icon-box h4 a {
  color: #282828;
  transition: ease-in-out 0.3s;
}
.vantagens .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.vantagens .icon-box:hover {
  transform: translateY(-10px);
  border-color: #e51937;
}
.vantagens .icon-box:hover h4 a {
  color: #e51937;
}

/* media 1 */

.section-title-vantagens {
  text-align: center;
  padding: 30px 0;
  position: relative;
}
.section-title-vantagens h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}
.section-title-vantagens span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .section-title-vantagens span {
        font-size:55px;
        top: 30px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .section-title-vantagens span {
        font-size:48px;
        top: 30px;
    }
}

@media(max-width:767px){
    .section-title-vantagens span {
        font-size:35px;
        top: 30px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .section-title-vantagens span {
        font-size: 30px;
        top: 30px;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .section-title-vantagens span {
        font-size:25px;
        top: 30px;
    }
}


.section-title p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

/*----- FIM VANTAGENS -----*/
/*-------------------------*/



/*---------------------*/
/*----- SEGURANCA -----*/
section {
  padding: 30px 0;
  overflow: hidden;
}

.section-title-seguranca {
  text-align: center;
  padding: 30px 0;
  position: relative;
}
.section-title-seguranca h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}
.section-title-seguranca span {
  position: absolute;
  top: 1px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 42px;
  text-transform: uppercase;
  line-height: 1;
}
.section-title-seguranca p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .section-title-seguranca span {
      font-size: 42px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .section-title-seguranca span {
      font-size: 38px;
      top: 30px;
    }
}

@media(max-width:767px){
    .section-title-seguranca span {
      font-size: 38px;
      top: 30px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .section-title-seguranca span {
      font-size: 35px;
      top: 30px;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .section-title-seguranca span {
      font-size: 28px;
    }
}

/*----- FIM SEGURANCA -----*/
/*-------------------------*/



/*---------------------*/
/*----- PARCEIROS -----*/
.section-title-parceiros {
  text-align: center;
  padding: 30px 0;
  position: relative;
}
.section-title-parceiros h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}
.section-title-parceiros span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}
.section-title-parceiros p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}


.logos {
  background: #f2f2f2;
  padding: 15px 0;
  text-align: center;
}
.logos img {
  width: 50%;
  filter: grayscale(100);
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
}
.logos img:hover {
  filter: none;
  transform: scale(1.2);
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .section-title-parceiros span {
      font-size: 42px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .section-title-parceiros span {
      font-size: 38px;
    }
}

@media(max-width:767px){
    .section-title-parceiros span {
      font-size: 35px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .section-title-parceiros span {
      font-size: 32px;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .section-title-parceiros span {
      font-size: 30px;
    }
}

/*----- FIM PARCEIROS -----*/
/*-------------------------*/



/*---------------------*/
/*----- QUERO SER -----*/
section {
  padding: 30px 0;
  overflow: hidden;
}

.section-title-quero {
  text-align: center;
  padding: 30px 0;
  position: relative;
}
.section-title-quero h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}
.section-title-quero span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}
.section-title-quero p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .section-title-quero span {
      font-size: 42px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .section-title-quero span {
      font-size: 38px;
    }
}

@media(max-width:767px){
    .section-title-quero span {
      font-size: 35px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .section-title-quero span {
      font-size: 32px;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .section-title-quero span {
      font-size: 30px;
    }
}


/*----- FIM QUERO SER -----*/
/*-------------------------*/

.vantagens .icon-box .icon i {
  font-size: 28px;
}
.vantagens .icon-box h4 {
  font-weight: 700;
  justify-content: center;
  margin-bottom: 15px;
  font-size: 24px;
}
.vantagens .icon-box h4 a {
  color: #282828;
  transition: ease-in-out 0.3s;
}
.vantagens .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.vantagens .icon-box:hover {
  transform: translateY(-10px);
  border-color: #e51937;
}
.vantagens .icon-box:hover h4 a {
  color: #e51937;
}



/*----- FIM VANTAGENS -----*/


</style>
