<template>
  <section id="contato" class="contato">
    <div style="margin-top: 50px"></div>

    <div class="container">
      <div class="section-title" data-aos="fade-up">
        <span>Contato</span>
        <h2>Contato</h2>
        <p>Entre em contato para maiores informações</p>
      </div>

    <div class="row" data-aos="fade-up">
      <div class="col-lg-6 d-flex justify-content-end">
          <iframe class="" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7326.37375543067!2d-52.098468!3d-23.3452432!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ed2b4df8442d11%3A0x19ce2dbb28706616!2sR.%2014%20De%20Dezembro%2C%2045%20-%20Centro%2C%20Mandagua%C3%A7u%20-%20PR%2C%2087160-000!5e0!3m2!1spt-BR!2sbr!4v1645221974304!5m2!1spt-BR!2sbr" frameborder="0" style="border: 0; width: 100%; height: 430px" allowfullscreen></iframe>
      </div>

        <div class="col-lg-6">
          <div class="row">
            <div class="col">
              <div class="info-box mb-4">
                <a href="mailto:contato@creditall.com.br">
                  <i class="bi bi-envelope fa-xs" style=" font-size: 48px; height: 72px; width: 72px; padding-left: 0.62rem;"></i>
                  <h3>E-mail</h3>
                  <p class="emailText">contato@creditall.com.br</p>
                </a>
              </div>
            </div>

            <div class="col">
              <div class="info-box mb-4">
                <i class="bi bi-telephone fa-xs" style="font-size: 48px; height: 72px; width: 72px; padding-top: 0.6rem;"></i>
                <h3>Telefone</h3>
                <p>(41) 3205-1333</p>
              </div>
            </div>

            <div class="col">
              <div class="info-box mb-4">
                <a href="http://wa.me/5544999568919" target="blanck">
                  <i class="bx bi-whatsapp fa-xs" style="font-size: 48px; height: 72px; width: 72px; padding-left: 0.71rem;"></i>
                  <h3>Whatsapp</h3>
                  <p>(44) 99956-8919</p>
                </a>
              </div>
            </div>
          </div>

            <div class="row">
              <div class="col">
                <div class="info-box mb-4">
                  <a href="https://goo.gl/maps/RvKqPfXkoQ31Lfdg9" target="blanck">
                    <i class="bi bi-geo-alt fa-xs"></i>
                    <h3>Nosso endereço</h3>
                    <p>Rua 14 de Dezembro, 45 - Centro | Mandaguaçu - PR</p>
                  </a>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionContato",
};
</script>

<style scoped>
.contato .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
}
.contato .info-box i {
  font-size: 52px;
  color: #e51937;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #f5a0a0;
}
.contato .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}
.contato .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title {
  text-align: center;
  padding: 30px 0;
  position: relative;
}
.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}
.section-title span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}
.section-title p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

.section-title p:hover {
  color: #191919;
}


@media (max-width: 575px) {
  .section-title h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }
  .section-title span {
    font-size: 38px;
  }
}

p:hover {
  color: #e51937;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 896px) {
  .row {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .contato .info-box {
    width: auto;
    margin: 0 auto;
    padding: 2rem 0 1.875rem 0;
  }

  .contato .info-box p {
    font-size: 12px;
  }
}

</style>
