<template>
  <TopbarGA />
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
      <div class="col-lg-12">
      <ol>
        <li class="text-danger"><a href="/">Início</a></li>
        <li><a href="/garantia-aluguel">Garantia de Aluguel</a></li>
      </ol>
      <h2>Cadastro de Pré-venda</h2>
    </div>
    </div>
  </section>
  <!-- End Breadcrumbs -->

  <section class="inner-page mt-5">
    <div class="container">
      <h4 class="classic-title">
        <span style="border-bottom: 1px solid red">Insira os dados</span>
      </h4>
      <div style="margin-top: 50px"></div>

        <div class="col-lg-12">
          <div class="row">

            <div class="row">
              <div class="col-md-6 form-group">
                <input v-model="nome" type="text" name="name" class="form-control" id="name" placeholder="Nome" required/>
              </div>
              <div class="col-md-6 form-group mt-3 mt-md-0">
                <input v-model="cpf" type="text" class="form-control" name="subject" id="subject" placeholder="CPF/CNPJ" required/>
              </div>
            </div>
            <div class="form-group mt-3">
              <input v-model="email" type="email" class="form-control" name="email" id="email" placeholder="E-mail" required/>
              <br />
            </div>
            <div class="row">
              <div class="col-md-6 form-group">
                <input v-model="telefone" type="text" name="telefone" class="form-control" id="telefone" placeholder="Telefone" required/>
              </div>
              <div class="col-md-6 form-group mt-3 mt-md-0">
                <input v-model="celular" type="text" class="form-control" name="celular" id="celular" placeholder="Celular" required/>
                <br />
              </div>
            </div>

            <div class="row">
              <div class="col-md-3">
                <select v-model="uf" @change="onChange($event)" class="form-select" aria-label="Default select example">
                  <option selected>Estados</option>
                  <option value="PR">PR</option>
                  <option value="SC">SC</option>
                  <option value="RS">RS</option>
                  <option value="SP">SP</option>
                  <option value="RJ">RJ</option>
                  <option value="MG">MG</option>
                  <option value="ES">ES</option>
                  <option value="GO">GO</option>
                  <option value="MT">MT</option>
                  <option value="MS">MS</option>
                  <option value="TO">TO</option>
                  <option value="BA">BA</option>
                  <option value="SE">SE</option>
                  <option value="AL">AL</option>
                  <option value="PE">PE</option>
                  <option value="PB">PB</option>
                  <option value="RN">RN</option>
                  <option value="CE">CE</option>
                  <option value="PI">PI</option>
                  <option value="MA">MA</option>
                  <option value="PA">PA</option>
                  <option value="AM">AM</option>
                  <option value="AP">AP</option>
                  <option value="RR">RR</option>
                  <option value="AC">AC</option>
                  <option value="RN">RN</option>
                  <option value="DF">DF</option>
                </select>
              </div>
                <br>
              <div class="col-md-9">
                <select v-if="info" v-model="cidade" class="form-select" aria-label="Default select example">
                  <option v-for="nome in this.info.data.CIDADES" :key="nome">{{nome.nome}}</option>
                </select>
                 <select v-else class="form-select" aria-label="Default select example">
                  <option selected>Cidade</option>
                </select>
              </div>
            </div>
            <br>

            <div class="col-md-6 form-group">
              <p style="font-size: 18px;">Qual perfil você se encaixa:</p>
            </div>

            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-4 form-check col-md-6 form-group">
                  <input v-model="check" class="" type="checkbox" value="imobiliaria" id="produto1"/>
                  <label class="form-check-label" style="padding-left: 5px" for="produto1">Imobiliária e/ou Corretor(a)</label>
                </div>

                <div class="col-lg-4 form-check col-md-6 form-group">
                  <input v-model="check" class="" type="checkbox" value="dono" id="produto2"/>
                  <label class="form-check-label" style="padding-left: 5px" for="produto2">Preprietário de Imóveis</label>
                </div>

                <div class="col-lg-4 form-check col-md-6 form-group">
                  <input v-model="check" class="" type="checkbox" value="locatario" id="produto3"/>
                  <label class="form-check-label" style="padding-left: 5px" for="produto3">Quero Alugar(Inquilino)</label>
                </div>
              </div>
            </div>

            <div class="form-group mt-3">
              <textarea v-model="mensagem" class="form-control" name="message" rows="5" placeholder="Mensagem" required></textarea>
              <br />
            </div>

            <div class="text-center mb-5">
              <button v-on:click="salvar()" type="submit" class="btn btn-danger" style="border-radius: 0px;">Envie sua mensagem</button>
            </div>

        </div>
      </div>
    </div>
  </section>

  <FooterGA />
</template>

<script>
import TopbarGA from "../components/TopBarGA.vue";
import FooterGA from "../components/FooterGA.vue";
import axios from 'axios';
import Swal from 'sweetalert2'

export default {
  name: "CadastroGA",
  components: {
    TopbarGA,
    FooterGA,
  },
  data(){
    return{
      nome:null,
      email:null,
      cpf:null,
      uf:null,
      cidade:null,
      telefone:null,
      celular:null,
      mansagem:null,
      check:[],
      info:null
    }
  },
  methods:{
     onChange(val){
     console.log(val)
      axios
        .get(`https://siscredit.com.br/siscred/retorna_cidade_json.php?estado=${val.target.value}`)
        .then(res => (this.info = res))
        console.log(this.info)

    },
    salvar(){

      if(!this.nome||!this.cpf||!this.email||!this.celular||!this.uf||!this.cidade){
          Swal.fire(
              `Ops!`,
              `Preencha os campos`,
              `error`
               )
      }else{
         axios
        .post(`https://siscredit.com.br/siscred/web_rest.php?email_ga=true&nome=${this.nome}&email=${this.email}&telefone=${this.telefone}&celular=${this.celular}&cpf=${this.cpf}&uf=${this.uf}&cidade=${this.cidade}&mensagem=${this.mensagem}&check=${this.check}`)
        .then(resp =>

            console.log(resp),
              Swal.fire(
              `Email Enviado!`,
              `Aguarde nosso retorno`,
              `success`
               )
            )

        .catch(err =>
            Swal.fire(
              `Email não Enviado!`,
              `erro interno ${err}`,
              `error`
               )
        )

      }

    }
  }
};
</script>

<style scoped>
.breadcrumbs {
  padding: 45px 0;
  margin-top: 3rem;
  background: #efefef;
}
@media only screen and (max-width: 320px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}
</style>
