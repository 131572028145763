<template>

  <section id="lgpd" class="lgpd">
    <div class="container" data-aos="zoom-in">
      <div class="text-center">
        <h3>LEI GERAL DE PROTEÇÃO DE DADOS (LGPD)</h3>
        <p>
          A Lei Geral de Proteção de Dados (Lei N° 13.079/2019), mais conhecida
          como LGPD, foi sancionada em 14 de agosto de 2018. Seu principal
          objetivo é estabelecer regras e limites no que diz respeito ao
          tratamento de dados pessoais, visando a proteção dos direitos
          fundamentais, como os de liberdade e privacidade de seus titulares.
        </p>
        <a class="lgpd-btn" href="/lgpd">Saiba mais</a>
      </div>
    </div>
  </section>
</template>



<script>
export default {
  name: "SectionLGPD",
};
</script>


<style scoped>
.lgpd {
  background: linear-gradient(rgba(2, 2, 2, 0.5), rgba(0, 0, 0, 0.5)),
    url("/assets/images/photo2.jpg") fixed center center;
  background-size: cover;
  padding: 120px 0;
}

.lgpd h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.lgpd p {
  color: #fff;
}

.lgpd .lgpd-btn {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.lgpd .lgpd-btn:hover {
  background: #e51937;
  border: 2px solid #e51937;
}
</style>



