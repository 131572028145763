<template>
  <TopBar />
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
      <div class="col-lg-12">
        <ol>
          <li class="text-danger"><a href="/">Início</a></li>
          <li><a href="/sou-consumidor" class="text-dark">Sou consumidor</a></li>
        </ol>
        <h2>Análise de Créditos</h2>
      </div>
    </div>
  </section>

  <section class="inner-page mt-5">
    <div class="container">
      <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-4 order-1 order-lg-2" data-aos="fade-left">
          <img src="assets/images/analise-de-credito.png" class="img-fluid" style="margin-left: 50px;" width="300" height="300" alt=""/>
        </div>
        <div class="col-lg-8 pt-4 pt-lg-0 order-2 order-lg-1 content">
          <h4 class="classic-title"><span style="border-bottom: 1px solid red">Análise de Crédito</span></h4><br/>
          <p>
            Neste espaço você consumidor pode solicitar avaliação de seu limite
            de crédito, para isso basta preencher o cadastro e digitalizar a
            relação de documentos e anexar os arquivos ou, se preferir, enviar a
            documentação para o nosso endereço que se encontra no rodapé.
          </p>
          <div style="margin-top: 50px"></div>

          <form role="form" class="guia" id="contact-form" method="post">
            <div class="row">
              <div class="col-md-4">
                <label for="cnpj">
                  <strong>Nome</strong>
                  <span class="required text-danger">*</span>
                </label>
                <input type="text" size="30" value aria-required="true" onchange="AplicarMascaraCpfCnpj(this)" class="form-control" autocomplete="off" required/>
              </div>

              <div class="col-md-4">
                <label for="token">
                  <strong>Email</strong>
                  <span class="required text-danger">*</span>
                </label>
                <input type="text" size="30" value aria-required="true" onchange="AplicarMascaraCpfCnpj(this)" class="form-control" autocomplete="off" required/>
              </div>

              <div class="col-md-4">
                <label for="token">
                  <strong>Telefone</strong>
                  <span class="required text-danger">*</span>
                </label>
                <input type="text" size="30" value aria-required="true" onchange="AplicarMascaraCpfCnpj(this)" class="form-control" autocomplete="off" required/>
              </div>
            </div>
            <br />

            <div class="row">
              <div class="col-md-7">
                <label for="cnpj">
                  <strong>Endereço</strong>
                  <span class="required text-danger">*</span>
                </label>
                <input type="text" size="30" value aria-required="true" onchange="AplicarMascaraCpfCnpj(this)" class="form-control" autocomplete="off" required/>
              </div>

              <div class="col-md-3">
                <label for="token">
                  <strong>Cidade</strong>
                  <span class="required text-danger">*</span>
                </label>
                <input type="text" size="30" value aria-required="true" onchange="AplicarMascaraCpfCnpj(this)" class="form-control" autocomplete="off" required/>
              </div>

              <div class="col-md-2">
                <label for="token">
                  <strong>Estado</strong>
                  <span class="required text-danger">*</span>
                </label>
                <input type="text" size="30" value aria-required="true" onchange="AplicarMascaraCpfCnpj(this)" class="form-control" autocomplete="off" required/>
              </div>
              <div style="margin-top: 25px"></div>

              <div class="col-md-10">
                <label for="token">
                  <strong>Arquivo</strong>
                  <span class="required text-danger">*</span>
                </label>
                <div class="input-group mb-3">
                  <input type="file" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01"/>
                  <label class="custom-file-label" for="inputGroupFile01"></label>
                </div>
              </div>

              <div class="form-group mt-3 mb-5">
                <textarea class="form-control" name="message" rows="5" placeholder="Mensagem" required></textarea>
                <br />
                <input name="submit" type="submit" id="submit" value="Enviar" class="btn btn-danger"/>
              </div>
            </div>
          </form>
        </div>
      </div>
      </div>
    </div>
  </section>

  <Footer />
</template>

<script>
import Footer from "../components/Footer.vue";
import TopBar from "../components/TopBar.vue";

export default {
  name: "AnaliseCredito",
  components: {
    TopBar,
    Footer,
  },
};
</script>

<style scoped>
.breadcrumbs {
  padding: 45px 0;
  margin-top: 4rem;
  background: #efefef;
}
@media only screen and (max-width: 320px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}
</style>
