<template>
  <TopBar />
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
      <div class="col-lg-12">
        <ol>
          <li class="text-danger"><a href="/">Início</a></li>
          <li><a href="/empresa">Sobre</a></li>
        </ol>
        <h2>A empresa</h2>
      </div>
    </div>
  </section>
  <!-- End Breadcrumbs -->

  <section class="inner-page mt-5">
    <div class="container">
      <div class="col-lg-12">
      <div class="row mb-5">
        <div class="col-lg-6 order-1 order-lg-2" data-aos="fade-left">
          <iframe src="https://www.youtube.com/embed/tNCdiC_82jc?rel=0&amp;showinfo=0?ecver=2" width="100%" height="400"></iframe>
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
          <h4 class="classic-title">
            <span style="border-bottom: 1px solid red">Bem vindo a Creditall</span><br /><br />
          </h4>
          <p style="font-size: 13px">
            A Creditall possui experiência no mercado de crédito há mais de 14
            anos, proporcionando aos seus clientes competência e alta
            performance nas decisões de negócios.
          </p>
          <p style="font-size: 13px">
            Utilizando as mais avançadas tecnologias e sistemas inteligentes de
            abrangência nacional garantimos decisões com menor risco e maior
            rentabilidade.
          </p>
          <p style="font-size: 13px">
            A Creditall sediada em Curitiba - PR disponibiliza para todo o
            território nacional seus serviços de Garantia e Gestão de meios de pagamentos,
            proporcionando às empresas Segurança e Inteligência na análise e concessão de Crédito,
            através dos meios de pagamentos disponibilizados pela Creditall sua empresa obterá
            aumento significativo em suas operações comerciais, garantindo assim
            crescimento em seu faturamento.
          </p>
          <p style="font-size: 13px">
            Possuímos experiência em oferecer soluções integradas que abrangem
            todas as etapas do ciclo de crédito: Análise e Concessão de crédito,
            gestão da carteira de crédito, cobrança, prevenção a fraude e
            validação do perfil e potencial de compra do consumidor.
          </p>
        </div>
      </div>
      </div>
    </div>
  </section>

  <div style="margin-top: 75px;"></div>

  <Footer />
</template>

<script>
import Footer from "../components/Footer.vue";
import TopBar from "../components/TopBar.vue";

export default {
  name: "Empresa",
  components: {
    TopBar,
    Footer,
  },
};
</script>

<style scoped>
.breadcrumbs {
  padding: 45px 0;
  margin-top: 4rem;
  background: #efefef;
}
@media only screen and (max-width: 320px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}

@media screen and (max-width: 768px) {
  section, aside{
    width: 100%;
    padding: 0;
  }
}
</style>
