import { createApp } from 'vue';
import App from './App.vue';

import router from './router/index';

import AOS from 'aos';
import 'aos/dist/aos.css';

import './utils/global.css';



const app = createApp(App).use(router)


app.use(AOS.init());

app.mount('#app')