<template>
<div style="margin-top: 200px;"></div>
<section id="servicos" class="servicos">
  <div class="container">
      <div style="margin-top: 200px;"></div>
    <div class="section-title">
      <span>Produtos e serviços</span>
      <h2>Produtos e serviços</h2>
      <p>Conheça nossos serviços de garantia e gestão de meios de pagamentos</p>
    </div>

  <!-- GARANTIA DE CRÉDITO -->
  <section id="garantiacredito"></section>
  <div style="margin-top: 75px;"></div>
    <div class="row">
      <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
        <h3>Garantia de Crédito</h3>
        <div style="margin-top: 25px;"></div>
      </div>
    </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
          <div class="icon-box">
            <div class="text-align center">
              <a href="/produtos-e-servicos/boleto-garantido">
                <div class="icon"><i class="bi bi-upc-scan"></i></div>
                <h4>Boleto Garantido</h4>
                <p class="text-justify">Creditall Boleto Garantido é a forma mais segura e eficaz para um aumento significativo em suas vendas,
                  seja no varejo ou comercio eletronico!
                  Obtenha maior agilidade no processo de concessão de crédito e maior facilidade na gestão
                  de seu contas a receber, de forma prática, segura e com baixo custo.
                </p><div style="margin-top: 50px;"></div>
                <div class="icon-botao"><i class="bi bi-chevron-compact-right"></i></div></a>
            </div>
          </div>
        </div>


          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
            <div class="icon-box">
              <a href="/cartao-compras">
                <div class="icon"><i class="bi bi-credit-card"></i></div>
                <h4>Cartão de Compras</h4>
                <p class="text-justify">Cartão sem taxas de adesão ou anuidades, que oferece crédito
                  direto ao consumidor e até 45 dias para pagamento, por meio de
                  fatura ou boleto. Para o seu cliente, além de crédito pré-aprovado
                  e prazo para pagamento, contará com atendimento personalizado e sem burocracia,
                  com várias opções de data de vencimento, entre outros benefícios.</p><div style="margin-top: 50px;"></div>
                  <div class="icon-botao"><i class="bi bi-chevron-compact-right"></i></div></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
            <div class="icon-box">
              <a href="/garantia-crediario">
                <div class="icon"><i class="bi bi-patch-check"></i></div>
                <h4>Garantia de Crediário</h4>
                <p class="text-justify">Creditall Garantia de Crediário é a opção de pagamento em seu
                  estabelecimento. Uma linha de crédito fácil, sem burocracia e
                  com taxas competitivas, que irá ajudar a aumentar as suas
                  vendas, seus clientes e sua lucratividade.</p><div style="margin-top: 50px;"></div>
                  <div class="icon-botao"><i class="bi bi-chevron-compact-right"></i></div></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
            <div class="icon-box">
              <a href="/garantia-cheque">
                <div class="icon"><i class="bi bi-ui-checks"></i></div>
                <h4>Garantia de Cheque</h4>
                <p class="text-justify">Disponibilizamos para todo o território nacional o serviço
                  Creditall Garantia de Cheque, possibilitando um aumento no
                  faturamento graças a modalidade de vendas com cheques.</p><div style="margin-top: 50px;"></div>
                  <div class="icon-botao"><i class="bi bi-chevron-compact-right"></i></div></a>
            </div>
          </div>


          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
            <section id="garantiaaluguel"></section>
            <div class="icon-box">
              <a href="/garantia-aluguel">
                <div class="icon"><i class="bi bi-house-door"></i></div>
                <h4>Garantia de Aluguel <span class="badge rounded-pill bg-danger" style="background: #e51937; font-size: 10px">Novo</span></h4>
                <p>Confira o mais novo produto da Creditall!</p>
                <p class="text-justify">Alugue seu imóvel de forma rápida, segura e eficiente com garantia em caso de inadimplência</p><div style="margin-top: 50px;"></div>
                  <div class="icon-botao"><i class="bi bi-chevron-compact-right"></i></div></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
            <div class="icon-box">
              <a href="/pix-parcelado-garantido">
                <div class="icon"><span class="material-icons" style="font-size: 40px;">pix</span></div>
                <h4>Pix Parcelado <span class="badge rounded-pill bg-danger" style="background: #e51937; font-size: 10px">Novo</span>
                <br>Garantido</h4>
                <p class="text-justify">Parcelamos via Pix suas vendas em até 12 vezes sem entrada e, com 100%
                  de garantia em caso de inadimplência.</p><div style="margin-top: 50px;"></div>
                  <div class="icon-botao"><i class="bi bi-chevron-compact-right"></i></div></a>
            </div>
          </div>
        </div>
          <!-- FINAL GARANTIA DE CRÉDITO -->

          <!-- GARANTIA DE ALUGUEL
          <section id="garantiaaluguel"></section>
            <div style="margin-top: 100px;"></div>
              <div class="row">
                <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
                  <h3>Garantia de Aluguel</h3>
                  <div style="margin-top: 25px;"></div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 col-md-6 d-flex align-items-stretch">
                  <div class="icon-box col-lg-12">
                    <a href="/garantia-aluguel">
                      <div class="icon"><i class="bi bi-house-door"></i></div>
                      <h4>Garantia de Aluguel</h4>
                      <p>Confira o mais novo produto da Creditall!</p>
                      <p>Alugue seu imóvel de forma rápida, segura e eficiente com garantia em caso de inadimplência</p></a>
                  </div>
                </div>
              </div>
             FINAL GARANTIA DE ALUGUEL -->

          <!-- PLATAFORMA DE CRÉDITO -->
          <section id="plataformacredito"></section>
            <div style="margin-top: 100px;"></div>
              <div class="row">
                <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
                  <h3>Plataforma de Crédito</h3>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                  <div class="icon-box">
                    <a href="/gestao-cheque">
                    <div class="icon"><i class="bi bi-card-checklist"></i></div>
                    <h4>Gestão de Cheque</h4>
                    <p class="text-justify">
                      A Creditall disponibiliza para todo o território nacional seu
                      serviço Creditall Gestão de Cheques, possibilitando às empresas
                      do varejo através da modalidade de venda com cheques à vista e
                      pré-datados aumento significativo em seu faturamento.</p><div style="margin-top: 50px;"></div>
                  <div class="icon-botao"><i class="bi bi-chevron-compact-right"></i></div></a>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                  <div class="icon-box">
                    <a href="/gestao-crediario">
                    <div class="icon"><i class="bi bi-receipt-cutoff"></i></div>
                    <h4>Gestão de Crediário</h4>
                    <p class="text-justify">
                      O Crediário devido a sua versatilidade, facilidade de controle e
                      pagamento para o cliente é um dos principais meios de venda para
                      grandes redes varejistas.</p><div style="margin-top: 50px;"></div>
                  <div class="icon-botao"><i class="bi bi-chevron-compact-right"></i></div></a>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                  <div class="icon-box">
                    <a href="/gestao-compras">
                    <div class="icon"><i class="bi bi-cart-check"></i></div>
                    <h4>Gestão de Compras</h4>
                    <p class="text-justify">
                      Ferramentas avançadas de análise e concessão de crédito,
                      avaliação comportamental e perfil de compras</p><div style="margin-top: 50px;"></div>
                  <div class="icon-botao"><i class="bi bi-chevron-compact-right"></i></div></a>
                  </div>
                </div>
              </div>
      <!-- FINAL PLATAFORMA DE CRÉDITO -->
      <div style="margin-top: 100px;"></div>

    </div>
  </section>
</template>


<script>
export default {
  name: "SectionServicos",
};
</script>

<style scoped>
.icon-box a#botao.btn.btn-danger.text-light {
  background-color:#e51937;
  position: absolute;
  bottom: 0;
  border: #fff;
}

.icone-pix {
  width: 60px;
  height: 60px;
}

.servicos .icon-box {
  padding: 60px 30px;
  transition: all ease-in-out 0.3s;
  background: #fefefe;
  box-shadow: 0px 50px 90px 0px rgba(110, 123, 131, 0.1);
  border-radius: 18px;
  border-bottom: 5px solid #fff;
}

.servicos .icon-box .icon {
  width: 64px;
  height: 64px;
  background: #e51937;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: ease-in-out 0.3s;
  color: #fff;
}

.servicos .icon-box .icon-botao{
  position: relative;
  width: 44px;
  height: 44px;
  background: #e51937;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
  color: #fff;
}

.servicos .icon-box img {
  width: 10rem;
  height: 10rem;
  position: absolute;
  top: -5rem;
  left: 91%;
}

.servicos .icon-box .icon i {
  font-size: 28px;
}

.servicos .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.servicos .icon-box h4 a {
  color: #282828;
  transition: ease-in-out 0.3s;
}

.servicos .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.servicos .icon-box:hover {
  transform: translateY(-10px);
  border-color: #e51937;
}

.servicos .icon-box:hover h4 a {
  color: #e51937;
}

/* media 1 */

.section-title {
  text-align: center;
  padding: 30px 0;
  position: relative;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}

.section-title span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .section-title span {
        font-size:55px;
        top: 30px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .section-title span {
        font-size:48px;
        top: 30px;
    }
}

@media(max-width:767px){
    .section-title span {
        font-size:35px;
        top: 30px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .section-title span {
        font-size: 30px;
        top: 30px;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .section-title span {
        font-size:25px;
        top: 30px;
    }
}

.section-title p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

/* media 2*/
</style>
