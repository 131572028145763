<template>
  <TopBar />
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
      <div class="col-lg-12">
        <ol>
          <li class="text-danger"><a href="/">Início</a></li>
          <li>Área do Cliente</li>
        </ol>
        <h2>Sou Lojista</h2>
      </div>
    </div>
  </section>
  <!-- End Breadcrumbs -->

  <section class="inner-page mt-5">
    <div class="container">
      <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-5 order-lg-2 order-lg-1" data-aos="fade-right">
          <img src="assets/images/contact_bgaa.jpg" height="300px;" class="img-fluid" alt="" />
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 order-1 order-lg-2 content">
          <div class="col-md-8 page-content">
            <h4 class="classic-title">
              <span style="border-bottom: 1px solid red">Sistema</span>
            </h4>
            <p>Se ja é nosso cliente, acesse nosso sistema.</p>
            <a href="https://www.siscredit.com.br/" class="btn btn-danger" target="_blanck">ACESSAR</a>
            <div style="margin-top: 25px"></div>
          </div>

          <div class="col-md-7 page-content">
            <h4 class="classic-title">
              <span style="border-bottom: 1px solid red">Quero me tornar cliente</span>
            </h4>
            <p>
              Caso ainda não seja cliente, preencha os dados para marcar uma
              apresentação sem compromisso ou, entre em contato para maiores
              informações.
            </p>
            <p><a href="/marcar-reuniao" class="btn btn-danger" style="border-radius: 0px;">Marcar reunião</a>
              <a href="/#contato" class="btn btn-danger" style="margin-left: 20px; border-radius: 0px;">Contato</a></p>

            <div class="col-md-8 page-content mb-5">
              <p>&nbsp;</p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </section>
  <Footer />
</template>

<script>
import Footer from "../components/Footer.vue";
import TopBar from "../components/TopBar.vue";

export default {
  name: "SouLojista",
  components: {
    TopBar,
    Footer,
  },
};
</script>

<style scoped>
.breadcrumbs {
  padding: 45px 0;
  margin-top: 4rem;
  background: #efefef;
}
@media only screen and (max-width: 320px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}
</style>
