<template>
  <TopBar />
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
      <div class="col-lg-12">
        <ol>
          <li class="text-danger"><a href="/">Início</a></li>
          <li><a href="/trabalhe-conosco">Trabalhe Conosco</a></li>
        </ol>
        <h2>Licenciado</h2>
      </div>
    </div>
  </section>
  <!-- End Breadcrumbs -->

  <section class="inner-page mt-5">
    <div class="container">
      <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-4 order-1 order-lg-2" data-aos="fade-left">
          <img src="assets/images/trabalhe-conosco.png" class="img-fluid justify-content-center" alt=""/>
        </div>
        <div class="col-lg-8 pt-4 pt-lg-0 order-2 order-lg-1 content">
          <h4 class="classic-title">
            <span style="border-bottom: 1px solid red">Licenciado</span>
          </h4>
          <p>
            Venha ser nosso licenciado! Temos os melhores produtos do mercado em
            gestão e garantia de crédito.A Creditall está abrindo oportunidades
            para empreender, se você é um apaixonado por negócios, é curioso em
            aprender, tem perfil empreendedor e possui disponibilidade para
            atuar em diferentes cidades, essa oportunidade tem a sua cara.
            Preencha o cadastro abaixo e transforme o seu potencial em uma
            carreira de sucesso.
          </p>
          <br>

          <div class="row">
            <div class="col-lg-12">

                <div class="row">
                  <div class="col-md-12 form-group">
                    <input v-model="nome" type="text" name="name" class="form-control" id="name" placeholder="Nome*" required/>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <input v-model="email" type="email" class="form-control" name="email" id="email" placeholder="E-mail*" required/><br />
                </div>
                <div class="row">
                  <div class="col-md-6 form-group">
                    <input v-model="telefone" type="text" name="telefone" class="form-control" id="telefone" placeholder="Telefone*" required/>
                  </div>
                  <div class="col-md-6 form-group mt-3 mt-md-0">
                    <input v-model="celular" type="text" class="form-control" name="celular" id="celular" placeholder="Celular*" required/><br />
                  </div>

                  <div class="col-md-6">
                    <label>Curriculo:<span class="required text-danger">*</span></label>
                    <div class="input-group">
                      <input v-on:change="upload" type="file" name="file" multiple ref="file" class="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload">
                    </div>
                  </div>
                </div>

                <div style="margin-top: 25px"></div>

             <div class="col-lg-12">
              <div class="row">
                <label>Setor:<span class="required text-danger">*</span></label>
                <div style="margin-top: 15px"></div>
                <div class="col-lg-4 form-check col-md-6 form-group">
                  <input v-model="check" class="" type="checkbox" value="comercial" id="produto1"/>
                  <label class="form-check-label" style="padding-left: 5px" for="produto1">Comercial</label>
                </div>

                <div class="col-lg-4 form-check col-md-6 form-group">
                  <input v-model="check" class="" type="checkbox" value="cobranca" id="produto3"/>
                  <label class="form-check-label" style="padding-left: 5px" for="produto3">Cobrança</label>
                </div>
                
              </div>
            </div>

                <div class="form-group mt-3">
                  <textarea v-model="mensagem" class="form-control" name="message" rows="5" placeholder="Mensagem" required></textarea><br />
                </div>
                <div class="text-center mb-5">
                  <button v-on:click="salvar"  class="btn btn-danger" style="border-radius: 0px;">Envie sua mensagem</button>
                </div>

            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </section>

  <Footer />
</template>

<script>
import Footer from "../components/Footer.vue";
import TopBar from "../components/TopBar.vue";
import axios from 'axios';
import Swal from 'sweetalert2'

export default {
  name: "Licenciado",
  components: {
    TopBar,
    Footer,
  },
  data(){

    return{
      nome:null,
      email:null,
      telefone:null,
      celular:null,
      mensagem:null,
      someData:[],
      check:[],
      file:''

    }
  },
  methods:{

    salvar(){
    let formData = new FormData();
    formData.append('file',this.file)
  if(!this.nome||!this.email||!this.celular){
          Swal.fire(
              `Ops!`,
              `Preencha os campos`,
              `error`
               )
      }else{
      axios
        .post(`https://siscredit.com.br/siscred/web_rest.php?email_trabalho=true&licenciado=true&nome=${this.nome}&email=${this.email}&telefone=${this.telefone}&celular=${this.celular}&mensagem=${this.mensagem}&check=${this.check}`,
        formData,
        {
          headers:{
            'Content-Type':'multipart/form-data'
          }
        }
        )
        .then(resp =>

            console.log(resp.data),
              Swal.fire(
              `Email Enviado!`,
              `Aguarde nosso retorno`,
              `success`
               )
            )

        .catch(err =>
            Swal.fire(
              `Email não Enviado!`,
              `erro interno ${err}`,
              `error`
               )
        )

      }
    },
    upload(){
      this.file = this.$refs.file.files[0];
    }

  }
};
</script>

<style scoped>
.breadcrumbs {
  padding: 45px 0;
  margin-top: 4rem;
  background: #efefef;
}
@media only screen and (max-width: 320px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}
</style>
